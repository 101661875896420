/* Global Styles */
@import url('https://fonts.cdnfonts.com/css/modern-no-20');
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

.App {
  text-align: center;
  position: relative;
  top: 45px;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: "Titillium Web", sans-serif;
}

:root {
	--mainColor: white;
	--mainColorLight: #5767aa;
	--secondaryColor: #db2b39;
	--textColor: red;
  --backgroundColor: black;
}

header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 120px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  border-bottom: 3px solid var(--textColor); 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
}


nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 10;
	}

	header nav {
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 1s;
	}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}
}

header .navbar-logo {
  display: flex;
  align-items: center;
}

header .navbar-logo img {
  padding-top: 3px;
  height: 150px; /* Adjust this value to fit the navbar height */
  width: 125px;  /* Maintain aspect ratio */
}

/* Topbar Styles */
.topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: transparent;
  color: white;
  border-bottom: 1px solid gray;
  z-index: 1000; 
  margin-left: 50px;
  margin-right: 50px;
}

.topbar-location-link {
  color: white;
  text-decoration: none;
}

.topbar-location {
  text-align: left;
}

.topbar-socials-link {
  color: white;
  text-decoration: none;
}

.topbar-socials {
  display: flex;
}

.topbar-socials > * {
  margin-left: 10px;
}

/* Responsive Topbar Styles */
@media (max-width: 768px) {
  .topbar {
    flex-direction: row;
  }

  .topbar-location {
    text-align: left;
    margin-right: auto;
  }

  .topbar-socials {
    margin-left: auto;
  }

  .topbar-socials > * {
    margin-left: 10px;
  }
}

/* Image Styles */
.App-Img {
  position: relative;
  top: -25px;
}

.App-Img-1 {
  width: 100%;
}

/* Photo Text Styles */
.photo-text {
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.photo-text h1 {
  color: white;
  font-size: 70px;
  font-family: 'Modern No. 20', sans-serif;
  color: #fff;
  white-space: nowrap;
}
.photo-text p {
  color: white;
  font-size: 35px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  white-space: nowrap;
  margin-top: -25px;
}

/* Responsive Photo Text Styles */
@media (max-width: 768px) {
  .photo-text {
    top: 88%; /* Adjust the vertical position for smaller screens if needed */
  }

  .photo-text h1 {
    font-size: 45px;
    
  }

  .photo-text p {
    font-size: 20px;
    margin-top: -20px;
  }
  .App-Img-1 {
    width: 100%;
   height: 45vh;
  }
}

.transparent-button {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding as needed */
  background-color: transparent;
  margin: 0 10px;
  color: white;
  border: 2px solid white;
  text-decoration: none;
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.transparent-button:hover {
  background-color: red;
  color: black;
}

@media (max-width: 768px) {
  .transparent-button {
    font-size: 12px; /* Adjust font size for mobile view */
    padding: 8px 16px; /* Adjust padding for mobile view */
    margin-top: -20px;
  }
}


.footer {
  background-color: white; 
  color: black;
  padding: 20px;
  display: flex;
  justify-content: space-between; 
  align-items: flex-start; 
  flex-wrap: wrap;
  margin-top: auto;
}

.socials {
  display: flex;
  justify-content: space-between;
  width: 100%; 
  flex-wrap: nowrap; 
}

.LasVegas, .Kenosha {
  margin-right: 20px; 
  flex: 1; 
}

h3, h4 {
  margin-bottom: 1px;
}

a {
  color: white;
  text-decoration: none;
  display: block; 
  margin-bottom: -10px; 
}

a:hover {
  text-decoration: underline; 
}


@media (max-width: 768px) {
  .footer {
    flex-direction: column; 
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }

  .socials {
    flex-direction: row; 
    justify-content: center; 
  }

  .LasVegas, .Kenosha {
    margin-right: 10px; /* Adjust margin for smaller screens */
    margin-bottom: 10px; /* Space between items when stacked */
  }
}

.socials {
  text-align: center;
;
}

.socials h4 {
  color: red;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  white-space: nowrap;
}

.socials a{
  color: black;
  font-weight: bold;
}


main {
  padding-top: 100px;
}

.back-to-top-btn {
  position: fixed;
  bottom: 50px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-color: #333; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 50%;
  width: 50px; /* Adjust button size */
  height: 50px; /* Adjust button size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure button is above other content */
  opacity: 0.7;
  transition: opacity 0.3s, transform 0.3s;
}

.back-to-top-btn:hover {
  opacity: 1;
}

.back-to-top-btn:active {
  transform: scale(0.9); /* Add some scaling effect when clicked */
}

.back-to-top-btn svg {
  font-size: 24px; /* Adjust icon size */
}

.photo-gallery {
  position: relative;
}

.photo-gallery img {
  width: 100vw;
  height: 50vh;
  object-fit: cover;
}

@media (max-width: 768px) {
  .photo-gallery img {
    height: 25vh;
  }
}

.locations-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allows wrapping to next line on small screens */
  gap: 20px; /* Adjust gap between locations */
}

.location {
  flex: 1;
  min-width: 300px; /* Adjust based on map size and preference */
  max-width: 50%; /* Ensures the locations don't grow too wide */
  box-sizing: border-box;
}

.map {
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
  position: relative;
}

.map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Mobile styles */
@media (max-width: 768px) {
  .locations-container {
      flex-direction: column;
      align-items: center;
  }
}

.location h3 {
  color: red;
}

.location-title {
  text-decoration: none;
  padding-bottom: 10px;
}

.footer-title {
  text-decoration: none;
}

.gallery-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
  width: 80%; 
  max-width: 1200px; 
}

.galleryItem {
  width: 100%; /* Full width of the grid cell */
  height: 200px; /* Fixed height for the gallery item */
  overflow: hidden; /* Hide any overflow to maintain square or rectangular shape */
  display: flex;
  align-items: center; /* Center the image vertically */
  justify-content: center; /* Center the image horizontally */
}

.galleryItem img {
  width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded corners */
  display: block;
}

@media (max-width: 768px) {
  .gallery {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .gallery {
      grid-template-columns: repeat(2, 1fr);
  }
}

.las-vegas {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

/* General styles */
.las-vegas {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Image Section */
.image-section {
  text-align: center;
  margin: 20px 0;
}

.image-section img {
  width: 100%;
  height: 700px;
}

/* Kenosha Section */
.image-section-kenosha {
  text-align: center;
  margin: 20px 0;
}

.image-section-kenosha img {
  width: 103%;
  height: 700px;
}

/* Barbers Section */
.barbers-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}

.barber-card {
  width: 30%;
  text-align: center;
  border: 1px solid #ddd; /* Optional border for clarity */
  border-radius: 10px;
  overflow: hidden; /* Ensure content stays within card */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Optional shadow */
  background: #fff; /* Card background color */
}

.barber-image {
  width: 100%;
  height: auto;
}

.barber-info {
  padding: 15px; /* Space around the text */
}

.book-appointment {
  color: red;
  text-decoration: none;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}

/* About Section */
.about-section {
  text-align: center;
}

.about-section span {
  display: block; 
  margin-bottom: 1px; 
}

.about-section p {
  color: red;
}

.about-section a {
  color: red;
}

.info{
  font-weight: bold;
  color: black;
}

/* Responsive Design for Mobile View */
@media (max-width: 768px) {
  .barbers-section {
    flex-direction: row; /* Align cards horizontally */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    justify-content: center; /* Align items to start */
    gap: 20px; /* Space between cards */
    padding: 0 10px; /* Optional padding */
  }

  .barber-card {
    flex: 0 0 auto; /* Prevent cards from shrinking */
    width: 195px; 
    margin-bottom: 0; 
  }

  .image-section img {
    height: auto;
    width: 100%;
  }

  .image-section-kenosha img {
    height: 325px;
    width: 103%;
  }

  .elfsight-app-dbf89d42-7e82-41eb-9b5c-02d7e8918a50 {
    padding-top: 25px;
  }
}

.location-title h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.location-cards {
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  gap: 20px; /* Space between the cards */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  padding: 20px; /* Add some padding around */
}

/* Make sure the cards are aligned next to each other in web view */
.location-card-lv,
.location-card-kn {
  width: 300px; /* Set a fixed width for the cards in web view */
  text-align: center; /* Center the text */
}


.location-card-img {
  width: 100%; 
  height: auto;
  max-width: 250px; 
  margin: 0 auto; 
}


@media (max-width: 768px) {
  
  .location-cards {
      flex-direction: column; 
      align-items: center; 
  }

  .location-card-lv,
  .location-card-kn {
      width: 80%; 
      margin-bottom: 20px; 
  }

  
  .location-card-img {
      max-width: 100%;
      height: 300px;
  }
}

.location-card-text, .location-title h1{
  font-weight: bold;
  font-family: Archivo Black, sans-serif;
}

.location-title h1{
  text-align: center;
  font-size: 45px;
}

.location-card-text {
  color: red;
  font-size: 25px;
}

.barber-link {
  color: red;
  text-decoration: none;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}
 html {
   scroll-behavior: smooth;
 }
